import React from 'react';
import Table, { OverflowTableWrapper } from '../../components/Table';
import DatabasePage, { LastRow } from '../../components/DatabasePage';
import PostgreSqlColorless from '../../images/databases/PostgreSqlColorless.svg';

export default function MariaDb() {
  return (
    <DatabasePage
      title="Ametnes for PostgreSQL"
      description="Ametnes for PostgreSQL is a fully managed SQL database, deployable in the cloud of your choice. Snap it into your existing workflows with the click of a button, automate away the mundane tasks, and focus on building your core apps."
      featuresTitle="Ametnes PostgreSQL Key Features"
      DbIcon={PostgreSqlColorless}
    >
      <OverflowTableWrapper>
        <Table>
          <thead>
            <tr>
              <th>Features</th>
              <th>Postgres 11.9</th>
              <th>Postgres 12.4</th>
              <th>Postgres 13.0 (beta)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Premium Tier</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Automated backups</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>High availability</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>7 day backup retention</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Unlimited databases</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Shared Instance</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Dedicated Instance</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>On demand database storage</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Pay only for what you use</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Secure TLS access</td>
              <td>✕</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <LastRow productCells={3} />
          </tbody>
        </Table>
      </OverflowTableWrapper>
    </DatabasePage>
  );
}
