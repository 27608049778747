import styled from 'styled-components';
import { device } from '../utils/breakpoints';

const StyledTable = styled.table`
  margin-top: 48px;
  background: ${(props) => props.theme.white};
  box-shadow: 0px -2px 53px rgba(20, 48, 189, 0.03),
    0px -0.835552px 22.1421px rgba(20, 48, 189, 0.0215656),
    0px -0.446726px 11.8382px rgba(20, 48, 189, 0.0178832),
    0px -0.250431px 6.63642px rgba(20, 48, 189, 0.015),
    0px -0.133002px 3.52455px rgba(20, 48, 189, 0.0121168),
    0px -0.0553451px 1.46665px rgba(20, 48, 189, 0.00843437);
  border-radius: 18px;

  & td:first-child {
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
  }

  & th {
    padding: 20px 72px;
    font-size: 28px;
    line-height: 110%;
    color: ${(props) => props.theme.primarys};
    border: none;
    text-align: center;
  }

  & td {
    border: none;
    padding: 20px 20px;
    text-align: center;
  }

  & td:not(:last-child) {
    border-right: 1px solid rgba(26, 74, 194, 0.1);
  }

  & th:not(:last-child) {
    border-right: 1px solid rgba(26, 74, 194, 0.1);
  }

  & th:first-child {
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    border-top-left-radius: 15px;
  }

  & .last > td {
    border-bottom-left-radius: 15px;
  }
`;

export const OverflowTableWrapper = styled.div`
  @media ${device.tablet} {
    overflow-y: auto;
  }
`;

export default StyledTable;
