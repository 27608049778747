import React from 'react';
import Seo from '../components/Seo';
import styled from 'styled-components';
import ButtonLink, { ButtonColor } from '../components/ButtonLink';
import JoinTheCloudSection from '../homeSections/JoinTheCloudSection';
import MarginContainer from '../components/MarginContainer';
import PageHeading from '../components/PageHeading';
import SmallLineDots from '../images/backgrounds/SmallLineDots.svg';
import { device } from '../utils/breakpoints';
import useCommonTitles from './useCommonTitles';

const ContentWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 70px;
`;

const BackgroundWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.backgroundSecondary};
`;

const StyledH2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;

  text-align: center;

  color: ${(props) => props.theme.primary};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;

  & a:not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledParagraphInverted = styled.p`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin: 0;
  padding: 0 16px;
  max-width: 550px;
  color: ${(props) => props.theme.white};
`;

const StyledParagraph = styled.p`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin: 0;
  padding: 0 16px;
  color: ${(props) => props.theme.dark};
`;

const CenteredButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DbIconWrapper = styled.div`
  position: absolute;
  right: 200px;
  top: 150px;

  @media ${device.tablet} {
    display: none;
  }
`;

const StyledSmallLineDots = styled(SmallLineDots)`
  position: absolute;
  right: 20px;
  bottom: -10px;
`;

const StyledSmallLineDots2 = styled(SmallLineDots)`
  position: absolute;
  left: 20px;
  bottom: -10px;
`;

function DatabasePage({ title, description, featuresTitle, children, DbIcon }) {
  const { getStarted } = useCommonTitles();
  return (
    <>
      <Seo title={title} />
      <PageHeading title={title} subtitle="Products">
        <div>
          <StyledParagraphInverted>{description}</StyledParagraphInverted>
          <ButtonsContainer>
            <ButtonLink color={ButtonColor.Secondary} href="/console/signup">
              {getStarted}
            </ButtonLink>
          </ButtonsContainer>
        </div>
        {DbIcon && (
          <DbIconWrapper>
            <DbIcon />
          </DbIconWrapper>
        )}
      </PageHeading>
      <BackgroundWrapper>
        <StyledSmallLineDots />
        <StyledSmallLineDots2 />
        <ContentWrapper>
          <MarginContainer>
            <StyledH2>{featuresTitle}</StyledH2>
            <StyledParagraph>Find the right product for you</StyledParagraph>
            {children}
          </MarginContainer>
        </ContentWrapper>
      </BackgroundWrapper>

      <JoinTheCloudSection />
    </>
  );
}

const StyledSmallLineDotsForTable = styled(SmallLineDots)`
  position: absolute;
  right: 20px;
  bottom: -11px;
`;

const DecoratedCell = styled.td`
  position: relative;
  height: 140px;
`;

export function LastRow({ productCells = 1 }) {
  const { getStarted } = useCommonTitles();
  return (
    <tr className="last">
      <DecoratedCell>
        <StyledSmallLineDotsForTable />
      </DecoratedCell>
      {Array.from(Array(productCells)).map((v, index) => (
        <td key={index}>
          <CenteredButton>
            <ButtonLink color={ButtonColor.Primary} to="/console/signup">
              {getStarted}
            </ButtonLink>
          </CenteredButton>
        </td>
      ))}
    </tr>
  );
}

export default DatabasePage;
